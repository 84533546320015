import { Controller } from 'stimulus'
import axios from 'axios'

export default class extends Controller {
  static targets = ['email', 'password', 'forgotPassLink', 'forgotPassForm', 'forgotPassInput', 'loginForm', 'locale']

  connect() {
    this.setupAuthenticityToken()
  }

  setupAuthenticityToken() {
    const csrfToken = document.querySelector("meta[name=csrf-token]").content
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
  }

  forgotPassword() {
    this.loginFormTarget.classList.add('hide')
    this.forgotPassFormTarget.classList.remove('hide')
  }

  signIn(e) {
    e.preventDefault()

    axios.post('/login?locale=' + this.localeTarget.dataset.locale, {
      user: {
        email: this.emailTarget.value,
        password: this.passwordTarget.value
      }
    }).then(response => {
      if(response.data.success) {
        window.location.replace(response.data.redirect_url)
      } else {
        this.loginError()
      }
    })
  }

  loginError() {
    document.getElementById('login-error').classList.remove('hide')
  }

}