import { Controller } from 'stimulus'
import axios from 'axios'
const cleave = require('cleave.js')

export default class extends Controller {
  static targets = [
    'instaUsername',
    'instaUsername1',
    'instaUsernameCopy',
    'fullName',
    'email',
    'spinner',
    'lock',
    'cardNumber',
    'cardExp',
    'cardCvc',
    'hHostedPage',
    'hipayData',
    'estherEgg',
    'estherEggVideo',
    'payForm'
  ]


  connect() {
    new Cleave('.cardNumber', {
      creditCard: true,
      numbers: true,
    })
    new Cleave('.exp-date', {
      date: true,
      datePattern: ['m', 'y']
    })


    this.hipay = HiPay({
      username: this.hipayDataTarget.dataset.publicUser,
      password: this.hipayDataTarget.dataset.publicPass,
      environment: this.hipayDataTarget.dataset.environment,
      lang: "en"
    })
  }

  displayError(error) {
    if (error) {
      document.getElementById('card-errors').classList.remove('hide')
      document.getElementById('card-errors').classList.add('d-block')
      document.getElementById('card-errors-msg').classList.remove('hide')
      document.getElementById('card-errors-msg').textContent = error
    } else {
      document.getElementById('card-errors').classList.add('hide')
      document.getElementById('card-errors').classList.remove('d-block')
      document.getElementById('card-errors-msg').classList.add('hide')
      document.getElementById('card-errors-msg').textContent = ''
    }
  }

  firstStep(e) {
    e.preventDefault()
    if(this.instaUsernameTarget.value == "C'est qui mon champion ??") {
      this.estherEggTarget.classList.remove('hidden')
      this.estherEggVideoTarget.src = this.estherEggTarget.dataset.src
      this.estherEggVideoTarget.play()
      this.payFormTarget.classList.add('hidden')
    }
    new bootstrap.Modal(document.getElementById('stepModal')).show()
    if(this.instaUsername1Target.value) {
      this.instaUsernameCopyTarget.innerText = this.instaUsername1Target.value
      this.username = this.instaUsername1Target.value
      this.instaUsername1Target.value = ''
    } else {
      this.instaUsernameCopyTarget.innerText = this.instaUsernameTarget.value
      this.username = this.instaUsernameTarget.value
      this.instaUsernameTarget.value = ''
    }
  }

  loadingActive() {
    this.lockTarget.classList.add('hidden')
    this.spinnerTarget.classList.remove('hidden')
  }

  loadingDisactive() {
    this.lockTarget.classList.remove('hidden')
    this.spinnerTarget.classList.add('hidden')
  }

  async pay(event) {
    this.loadingActive()
    // Setup authenticity token
    const csrfToken = document.querySelector("meta[name=csrf-token]").content
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
    event.preventDefault()

    const card = {
      cardNumber: this.cardNumberTarget.value.split(' ').join(''),
      expiryMonth: this.cardExpTarget.value.split('/')[0],
      expiryYear: this.cardExpTarget.value.split('/')[1],
      cvc: this.cardCvcTarget.value,
      cardHolder: this.fullNameTarget.value,
      multiUse: true
    }
    console.log(card)
    try {
      const paymentParams = await this.hipay.tokenize(card)
      console.log(paymentParams)
      try {
        const response = await axios.post('/pay', {
          payment: paymentParams,
          user: {
            username: this.username,
            full_name: this.fullNameTarget.value,
            email: this.emailTarget.value
          }
        })
        
        if(response.data.error) {
          this.displayError(response.data.error)
          this.loadingDisactive()
          return
        }

        const result = response.data.result
        switch (result.state) {
          case 'completed':
            window.location.href = response.data.redirect_url
            break;
          case 'forwarding':
            window.location.href = result.forwardUrl
            break;
          case 'declined':
            this.displayError("Le paiement a été refusé, veuillez contactez votre banque")
          default:
            break;
        }
      } catch (error) {
        console.log(error)
        this.displayError(error)
        this.loadingDisactive()
      }
    } catch(error) {
      console.log(error)
      this.displayError(error)
      this.loadingDisactive()
    }
  }

  async verifyExistanceOfAccount() {
    try {
      const response = await axios.get(`/verify_account?username=${this.username}`)
      console.log(response.data)
      if(response.data.exists) {
        this.exists = true
        this.loadingDisactive()
      } else {
        this.displayError("This account doesn't exist")
        this.loadingDisactive()
      }
    } 
    catch (error) {
      console.log(error)
      this.displayError(error)
      this.loadingDisactive()
    }
  }

  async generateHostedPage() {
    try {
      const response = await axios.post(`/hosted_page?username=${this.username}`)
      
      console.log(response.data)
      this.hHostedPageTarget.src = response.data.forwardUrl
    } 
    catch (error) {
      console.log(error)
      this.displayError(error)
      this.loadingDisactive()
    }
  }
}